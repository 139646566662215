import styled from 'styled-components';
import React, { FunctionComponent } from 'react';

const BarWrapper = styled.div`
	display: flex;
  	flex-direction: row;
  	margin-bottom: 4px;
  	width: 100%;
`;
const CheckIcon = styled.img``;

const BarContent = styled.span`
	color:  ${({ theme }) => theme.colors.fontBlue};
  	font-size: 0.75rem;
  	font-weight: 500;
  	margin-left: 5px;
`;
interface PatternBarProps {
	content: string;
	src: string;
}

const PatternBar: FunctionComponent<PatternBarProps> = ({ src, content }) => (
	<BarWrapper>
		<CheckIcon
			src={src}
		/>
		<BarContent>{content}</BarContent>
	</BarWrapper>
);
export default PatternBar;
